.curs {
  cursor: pointer;
}
.wallc {
    width: 100% !important;
    height: 44px !important;
  }
  .label2 {
    margin: auto;
    color: #ffffff !important;
  }
  .labelsh {
    color: #fff !important;
    flex: 1;
    cursor: default;
  }
  .mt-2 {
    width: 100% !important;
  }
  .container2 {
    display: flex;
    flex-basis: auto;
    justify-content: left;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
    transition: all 0.2s ease, visibility 0s;
  }
  .farf {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .farf i {
    font-size: 14px;
  }
  .control{
    margin-bottom: 0px;
  }
  .btn-primary.active {
    background: #6c54a3 !important;
  }
  .btn-primary.active.suc {
    background: #55889e !important;
  }
  .btn-primary {
    margin: 0 !important;
  }
  .notification.is-warning {
    background-color: rgba(238, 150, 33, 0.4) !important;
    color: rgba(0, 0, 0, 0.7);
  }
  .input {
    height: 49px !important;
  }
  .mint-button {
    width: 100%;
  }
  .rederror {
    color: red;
    font-style: inherit;
  }
  .buttonSubText {
    padding-top: 14px;
  }
  .btn-primary.active {
    margin-bottom: 16px !important;
  }
  .carrot-image {
    transform: rotate(-90deg);
  }
  .hero-subtitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 30px;
  }
  /* #minterbtn {
    padding-bottom: 16px;
  } */

.checkholder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
}
.input-conat {
  display: flex;
}
.checkholder input {
  width: 125px;
  margin-right: 6px;
}
.checkholder button {
  background: #e0983d !important;
  color: white;
  border-radius: 5px;
  padding: 0 13px;
}
.statsbox {
  padding-top: 8px;
  color: white;
  font-weight: bold;
}
.statslabel {
  font-size: 16px;
}
.checklabel {
  color: white; 
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 8px;
}
.yes {
  color: #aa3730;
}
.no {
  color: green;
}