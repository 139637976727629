html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(font.woff) format("woff");
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

html {
  scroll-behavior: smooth;
}

label {
  display: flex;
  margin: 3px 0;
  flex-direction: column;
  color: #fff;
}
@media (min-width: 767px) {
  label {
    flex-direction: row;
  }
}

label input {
  flex: 1 1;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 300px;
    font-weight: bold;
}


.timeline {
  width: 100%;
  background: transparent;
  border-radius: 10px;
}
.timeline h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  margin-top: 40px;
  color: #fff;
}
.timeline label {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
}
.timeline .box {
  width: 100%;
  margin-top: 40px;
}
.timeline .box .container {
  width: 100%;
  display: flex;
}
.timeline .box .container .lines {
  margin-top: 6px;
}
.timeline .box .container .lines .dot {
  width: 14px;
  height: 14px;
  background: var(--secondary);
  border-radius: 7px;
}
.claimh {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 17px;
  white-space: nowrap;
}
.closebtn {
  position: absolute;
  top: 9px;
  right: 9px;
}
.timeline .box .container .lines .line {
  height: 103px;
  width: 2px;
  background: var(--secondary);
  margin-left: 5.3px;
}
.timeline .box .container .cards {
  margin-left: 12px;
  transform: translateY(-20px);
  width: 100%;
}
.timeline .box .container .cards .card {
  height: 93px;
  background: #efe7b4;
  box-shadow: 0 2px 2px 0 #eee 40;
  border-radius: 10px;
  box-shadow: 0px 16px 15px -10px rgba(105, 96, 215, 0.0944602);
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.timeline .box .container .cards .card.mid {
  height: 71px;
}
.timeline .box .container .cards .card h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-left: 25px;
  margin-bottom: 5px;
  color: #2b2862;
}
.timeline .box .container .cards .card p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #2b2862;
  margin-left: 25px;
}
.timeline .box .bottom {
  width: 100%;
  height: 107px;
  background: #fff;
  box-shadow: 0 0 2px #eee 50;
  padding-top: 45px;
}
.timeline .box .bottom .btn {
  width: 249px;
  height: 62px;
  background: #fff 40;
  mix-blend-mode: normal;
  cursor: pointer;
  border: 1px solid #8260d7 80;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #2b2862;
  margin-left: 53px;
  transition: 0.3s;
  background: #2b2862;
  color: #fff;
  border-color: #2b2862;
}
.timeline .box .bottom .btn:hover {
  transform: scale(1.03);
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
select {
  flex: 1 1;
  padding-right: 4px;
}
button:disabled {
  cursor: not-allowed;
  opacity: .5;
}
.ital {
  font-style: italic;
  color: #666
}
.hidden {
  display: none !important;
}
.bolderred {
  color: var(--secondary) !important;
  font-weight: bold !important;
}
.thankyou {
  color: var(--third) !important;
  font-size: 16px !important;
}
.refix {
  box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI", "Helvetica Neue",Helvetica,Arial,sans-serif,"Apple Color Emoji", "Segoe UI Emoji","Segoe UI Symbol";
    color: #212121;
    background-color: #ffffff;
    position: fixed;
    top: 14%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 0.25rem;
    padding: 1em;
    max-height: calc(100vh - 56px);
    outline: 0;
    border: 1px solid rgba(33,33,33,0.25);
    color: #212121;
    z-index: 999;
    width: 380px;
    min-height: 114px;
}
.flutarsky {
  width: 100%;
  max-height: 60vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}
.backstyle {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  inset: 0px;
  z-index: 999;
  perspective: 800px;
  transition: opacity 250ms ease-in-out 0s;
  opacity: 1;
 }
.foots i {
  padding-right: 30px;
}
.foots a {
  outline: 0px;
  cursor: pointer;
}
.fa-twitter{
  color: #1da1f2;
}
.fa-instagram {
  color: #fb3958;
}
.fa-facebook-f {
  color: #3b5998;
}
.fa-ship {
  color: #2081e2;
}
.firstitem {
  position: absolute;
  left: 22px;
}
.boldy {
  font-weight: 700;
  font-size: 20px;
}
.clicklink {
  border: 0px;
    background: none;
    color: white;
    outline: 0;
}
.luluchoo {
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 21px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}
.headerdisc {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  font-weight: bold;
  cursor: pointer;
}
.heading1 {
  font-size: 39px;
  line-height: 38px;
}
.subheading1 {
  font-size: 19px;
  padding-top: 5px;
}
.turnbuck {
  width: 70%;
  margin-bottom: 21px;
}
.ihmys {
  font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    margin-top: 40px;
    color: #fff;
}
@media (max-width: 767px) {
  .firstitem {
    display: none;
  }
  
}