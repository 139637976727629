.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-commerce-commercelayoutmain {
  -webkit-flex-basis: 800px;
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 8px;
  height: 38px;
  min-height: 30px;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
  height: 38px;
}

.w-commerce-commercequickcheckoutgoogleicon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercequickcheckoutmicrosofticon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutemailinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left-color: #e6e6e6;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #e6e6e6;
  border-right-width: 1px;
  border-right-style: solid;
}

.w-commerce-commercecheckoutshippingmethoditem {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  margin-left: 12px;
  margin-right: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardnumber::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardexpirationdate::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardsecuritycode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  font-weight: 400;
  margin-left: 8px;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  margin-left: 16px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: pre-wrap;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  -webkit-flex-basis: 320px;
  -ms-flex-preferred-size: 320px;
  flex-basis: 320px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  text-align: center;
}

.w-commerce-commercecheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercepaypalcheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    margin-right: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .w-commerce-commercelayoutsidebar {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardnumber {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardexpirationdate {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardsecuritycode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }
}

body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

.navbar-no-shadow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-no-shadow-container {
  z-index: 5;
  width: 100%;
  max-width: 1140px;
  margin-right: 0px;
  margin-left: 0px;
  padding: 20px 40px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
}

.container-regular {
  width: 100%;
  max-width: 1260px;
  min-height: 30px;
  margin-right: auto;
  margin-left: auto;
}

.navbar-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: none;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  -o-object-fit: fill;
  object-fit: fill;
}

.navbar-logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.nav-menu-wrapper {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 0px;
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 1px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link:focus-visible {
  border-radius: 4px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-link[data-wf-focus-visible] {
  border-radius: 4px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown {
  margin-right: 5px;
  margin-left: 5px;
}

.nav-dropdown-toggle {
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-dropdown-toggle:focus-visible {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  border-radius: 12px;
  background-color: #fff;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-button-wrapper {
  margin-left: 120px;
}

.button-primary {
  margin-left: 20px;
  padding: 12px 25px;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary:hover {
  background-color: #32343a;
  color: #fff;
}

.button-primary:active {
  background-color: #43464d;
}

.image {
  float: left;
}

.navbar {
  position: initial;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 4;
  display: inline-block;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 100px;
  background-color: transparent;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent); */
  font-family: Roboto, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
  max-width: inherit !important;


  top: 0px;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}

.navbar.hide {
  top: -16rem;
  height: 0px;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1000ms;
}

.navbar-logo-left-container {
  z-index: 5;
  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: transparent;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: none;
  margin-bottom: 0px;
  padding: 0px;
}

.hero-gradient {
  overflow: visible;
  width: 100vw;
  height: 100%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
  background-image: linear-gradient(298deg, hsla(0, 0%, 100%, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.nav-menu-two {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-divider {
  width: 1px;
  height: 22px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #e4ebf3;
}

.nav-link-accent {
  margin-right: 20px;
  margin-left: 5px;
  padding: 5px 10px;
  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, 0.75);
}

.h1 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #000;
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  text-align: left;
  margin-top: 9px;
}

.h1.white {
  color: #fff;
}

.container-2 {
  height: 11%;
  max-width: 1400px;
}

.hero {
  position: static;
  display: block;
  height: 900px;
  max-width: 1400px;
  padding-right: 100px;
  padding-left: 100px;
  text-align: left;
  -o-object-fit: none;
  object-fit: none;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.title {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 3;
  display: block;
  overflow: visible;
  height: auto;
  max-width: 1400px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 130px;
  float: none;
  clear: none;
  direction: ltr;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 7vw;
  line-height: 120%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.image-2 {
  position: relative;
  z-index: 0;
  width: auto;
  max-width: 570px;
  float: none;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.hero-content-t-and-c {
  position: relative;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: block;
  height: 100%;
  max-width: 1400px;
  padding-top: 120px;
  float: none;
  text-align: center;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.body {
  font-family: Roboto, sans-serif;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.h2 {
  margin-bottom: 30px;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 50px;
  line-height: 65px;
  font-weight: 700;
  text-transform: uppercase;
}

.h2.black {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #333;
  line-height: 58px;
  letter-spacing: 1px;
}

.h2.black.left {
  text-align: left;
}

.h2.white {
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 58px;
  letter-spacing: 1px;
}

.container-3 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 1px;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.container-3.description {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
}

.section---white {
  max-width: none;
  text-align: center;
}

.paragraph {
  display: inline-block;
  overflow: auto;
  max-width: 750px;
  margin-bottom: 30px;
  padding-top: 20px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.paragraph.white {
  margin-bottom: 20px;
  padding-top: 0px;
  color: #fff;
  letter-spacing: 0px;
}

.paragraph.black {
  margin-bottom: 20px;
  padding-top: 0px;
  letter-spacing: 0px;
}

.text-center {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
}

.text-center.top {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.button {
  max-width: 200px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #5ab1b7;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text-block {
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}

.text-block.white {
  letter-spacing: 0px;
}

.text-block.black {
  color: #333;
}

.text-link {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 12px;
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.social-icon-right {
  display: inline-block;
  width: auto;
  max-width: none;
  min-width: 40px;
  padding-right: 20px;
  float: none;
  clear: none;
}

.list-item {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 60px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-item-2 {
  padding-right: 40px;
  padding-left: 40px;
}

.list-item-3 {
  padding-right: 40px;
}

.list-item-4 {
  padding-right: 40px;
}

.list-item-bottom {
  padding-right: 20px;
  padding-left: 0px;
}

.text-left {
  position: relative;
  display: block;
  overflow: auto;
  width: 50%;
  height: auto;
  float: none;
  font-family: Roboto, sans-serif;
  text-align: left;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.div-block-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.social-icon {
  display: inline-block;
  width: auto;
  max-width: none;
  min-width: 40px;
  padding-right: 0px;
  float: none;
}

.container-5 {
  max-width: 1400px;
  padding-top: 40px;
  padding-right: 100px;
  padding-left: 100px;
  text-align: left;
}

.div-block-3 {
  max-width: 1400px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.image-6 {
  float: right;
}

.link-block {
  float: right;
}

.we-are-x {
  position: static;
  display: block;
  overflow: visible;
  height: auto;
  max-width: 1400px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 180px;
  padding-left: 0px;
  float: none;
  clear: none;
  direction: ltr;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 7vw;
  line-height: 120%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.togeher-the-nerd-collective {
  position: static;
  display: block;
  overflow: visible;
  height: auto;
  max-width: 1400px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 30px;
  padding-left: 0px;
  float: none;
  clear: none;
  direction: ltr;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 7vw;
  line-height: 120%;
  font-weight: 700;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.team {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 100px;
  background-color: #ededed;
}

.container-6 {
  max-width: 1400px;
}

.grid {
  margin-top: 100px;
  grid-auto-flow: row;
  grid-column-gap: 6vw;
  grid-row-gap: 6vw;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.heading-2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  text-transform: uppercase;
}

.paragraph-2 {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 100%;
  max-width: 948px;
  margin-right: auto;
  margin-left: auto;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.div-block-4 {
  margin-right: auto;
  margin-left: auto;
}

.team-card {
  height: auto;
  border-radius: 8px;
  background-color: #fff;
}

.heading-3 {
  font-family: Roboto, sans-serif;
  font-size: 21px;
  line-height: 26px;
  font-weight: 500;
}

.text-block-2 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

.image-7 {
  width: 30px;
  height: 30px;
  text-align: center;
}

.column-3 {
  padding-right: 6px;
  padding-left: 0px;
  text-align: right;
}

.column-4 {
  padding-right: 0px;
  padding-left: 6px;
  text-align: left;
}

.columns-2 {
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

.div-block-6 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.image-8 {
  width: 30px;
  height: 30px;
}

.div-block-7 {
  margin-top: 16px;
}

.div-block-8 {
  border-radius: 5px;
}

.image-9 {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.footer {
  height: 600px;
  background-color: #353535;
  width: 100%;
}

.image-10 {
  height: 70px;
}

.div-block-10 {
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  /* padding-top: 200px; */
  padding-right: 100px;
  padding-left: 100px;
  text-align: center;
}

.div-block-11 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  /* height: 300px; */
  height: 260px;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 100px;
  padding-left: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.text-block-3 {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: auto;
  z-index: auto;
  font-family: Roboto, sans-serif;
  color: #868686;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 2px;
}

.section-standard {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #bebebe;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 1px;
}

.content-centered {
  max-width: 1400px;
  padding: 0px 100px;
}


.container-7 {
  height: 100%;
  max-width: 1400px;
  padding: 100px;
}

.div-block-12 {
  height: 100%;
}

.div-block-13 {
  position: relative;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 100%;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.heading-4 {
  position: relative;
  color: #333;
  font-size: 40px;
  line-height: 44px;
  text-align: left;
  text-transform: uppercase;
}

.paragraph-3 {
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.button-2 {
  display: inline-block;
  text-align: left;
}

.div-block-14 {
  position: static;
  top: 30%;
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: left;
}

.image-11 {
  width: 140px;
  height: 40px;
  max-height: 40px;
  max-width: 140%;
  min-height: 40px;
  min-width: 140px;
}


.hero-nerds.nerd-1 {
  overflow: hidden;
  opacity: 0;
}

.section---we-are-tnc {
  height: auto;
  max-width: none;
  background-image: linear-gradient(313deg, hsla(0, 0%, 100%, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb);
  text-align: center;
}

.heading-5 {
  font-weight: 900;
}

.heading-6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  font-size: 70px;
  line-height: 80px;
  font-weight: 700;
}

.text-span {
  text-decoration: underline;
}

.text-span-2 {
  font-size: 70px;
  line-height: 80px;
}

.text-span-3 {
  color: #fff;
}

.div-block-15 {
  position: relative;
}

.div-block-16 {
  padding: 60px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
}

.center-floating {
  position: relative;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
}

.homepage-module {
  max-width: none;
  text-align: center;
}

.homepage-module.text-only {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sub-title {
  padding-top: 50px;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.dropdown {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.container---faq {
  position: relative;
  z-index: 1;
  padding: 100px 5vw;
  background-color: white;
  width: 100%;
}

.div-block-20 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  min-height: 100%;
  min-width: 100px;
}

.dropdown-toggle {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.dropdown-toggle.w--open {
  background-color: #ddd;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.icon-2 {
  position: relative;
  left: auto;
  top: auto;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-left: 10px;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.text-block-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-family: Roboto, sans-serif;
  color: #000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.dropdown-list {
  padding-left: 20px;
}

.dropdown-list.w--open {
  margin-top: 70px;
  padding: 20px;
}

.header-faq {
  width: auto;
  max-width: 800px;
  padding-bottom: 12px;
  color: #000;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
}

.body-faq {
  width: auto;
  max-width: 800px;
  padding-bottom: 40px;
  font-size: 18px;
  line-height: 30px;
  text-align: left;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.faq-item {
  padding-top: 40px;
}

.div-faq {
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}

.div-block-23 {
  width: 100%;
  height: 1px;
  max-height: 1px;
  min-height: 1px;
  background-color: rgba(0, 0, 0, 0.39);
}

.paragraph-4 {
  color: #fff;
  text-align: left;
}

.column-5 {
  padding-right: 40px;
}

.column-6 {
  padding-right: 5vw;
  padding-left: 0px;
}

.div-block-24 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 10px;
  background-color: hsla(0, 0%, 100%, 0.8);
  text-align: left;
}

.heading-7 {
  display: block;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 30px;
  line-height: 35px;
  text-align: left;
}

.paragraph-5 {
  text-align: left;
}

.paragraph.text-left.disclaimer {	
  width: auto;	
  max-width: none;	
}

.div-block-26 {		
  display: -webkit-box;		
  display: -webkit-flex;		
  display: -ms-flexbox;		
  display: flex;		
  width: 100%;		
  padding: 10px 30px;		
  -webkit-box-align: center;		
  -webkit-align-items: center;		
  -ms-flex-align: center;		
  align-items: center;		
  border-style: solid;		
  border-width: 1px;		
  border-color: #fff;		
  border-radius: 5px;		
  text-decoration: none;		
}		
.column-8 {		
  display: -webkit-box;		
  display: -webkit-flex;		
  display: -ms-flexbox;		
  display: flex;		
  padding-right: 0px;		
  padding-left: 0px;		
  -webkit-box-pack: end;		
  -webkit-justify-content: flex-end;		
  -ms-flex-pack: end;		
  justify-content: flex-end;		
}		
.column-9 {		
  display: -webkit-box;		
  display: -webkit-flex;		
  display: -ms-flexbox;		
  display: flex;		
  padding-right: 0px;		
  padding-left: 0px;		
  -webkit-box-align: center;		
  -webkit-align-items: center;		
  -ms-flex-align: center;		
  align-items: center;		
}		
.columns-4 {		
  display: -webkit-box;		
  display: -webkit-flex;		
  display: -ms-flexbox;		
  display: flex;		
  margin-right: 0px;		
  margin-left: 0px;		
  -webkit-box-align: center;		
  -webkit-align-items: center;		
  -ms-flex-align: center;		
  align-items: center;		
}		
.container---disclaimer {		
  position: relative;		
  z-index: 1;		
  padding: 40px 5vw;		
}		
.div-disclaimer {		
  display: -webkit-box;		
  display: -webkit-flex;		
  display: -ms-flexbox;		
  display: flex;		
  max-width: 1200px;		
  margin-right: auto;		
  margin-left: auto;		
  -webkit-box-orient: vertical;		
  -webkit-box-direction: normal;		
  -webkit-flex-direction: column;		
  -ms-flex-direction: column;		
  flex-direction: column;		
  -webkit-box-align: start;		
  -webkit-align-items: flex-start;		
  -ms-flex-align: start;		
  align-items: flex-start;		
  color: #7e7e7e;		
}		
.paragraph-dislaimer {		
  display: inline-block;		
  overflow: auto;		
  width: 100%;		
  max-width: none;		
  margin-bottom: 0px;		
  padding-top: 0px;		
  font-family: Roboto, sans-serif;		
  color: #333;		
  font-size: 12px;		
  line-height: 30px;		
  font-weight: 400;		
  text-align: left;		
  -o-object-fit: fill;		
  object-fit: fill;		
  -o-object-position: 50% 50%;		
  object-position: 50% 50%;		
}		
.paragraph-dislaimer.white {		
  margin-bottom: 20px;		
  padding-top: 0px;		
  color: #fff;		
  letter-spacing: 0px;		
}		
.paragraph-dislaimer.black {		
  margin-bottom: 20px;		
  padding-top: 0px;		
  letter-spacing: 0px;		
}		
.paragraph-dislaimer.text-left.disclaimer {		
  width: auto;		
  max-width: none;		
  font-size: 12px;		
}		
.div-block-27 {		
  width: 100%;		
  max-width: 1200px;		
  margin-right: auto;		
  margin-left: auto;		
  padding-right: 0vw;		
  padding-left: 0vw;		
}		
.container---mint-details {		
  margin-top: 0px;		
  padding: 100px 5vw;		
  background-color: transparent;		
  background-image: linear-gradient(114deg, #fff, #d9d9d9);		
  width: 100%;
}		
.text---mint-details {		
  display: inline-block;		
  overflow: auto;		
  max-width: 600px;		
  margin-bottom: 0px;		
  padding-top: 0px;		
  font-family: Roboto, sans-serif;		
  color: #333;		
  font-size: 18px;		
  line-height: 33px;		
  font-weight: 400;		
  text-align: left;		
  -o-object-fit: fill;		
  object-fit: fill;		
  -o-object-position: 50% 50%;		
  object-position: 50% 50%;		
}		
.text---mint-details.white {		
  margin-bottom: 20px;	
  padding-top: 0px;	
  color: #fff;	
  letter-spacing: 0px;	
}	
.text---mint-details.black {	
  margin-bottom: 20px;	
  padding-top: 0px;	
  letter-spacing: 0px;	
}	
.text---mint-details.text-left.disclaimer {	
  width: auto;	
  max-width: none;	
}	
.text---mint-details.text-link {	
  text-decoration: underline;	
}	
.text---mint-details.link {	
  padding-right: 8px;	
  text-decoration: underline;	
}	
.column-10 {	
  display: -webkit-box;	
  display: -webkit-flex;	
  display: -ms-flexbox;	
  display: flex;	
  padding-right: 0px;	
  padding-left: 0px;	
  -webkit-box-orient: vertical;	
  -webkit-box-direction: normal;	
  -webkit-flex-direction: column;	
  -ms-flex-direction: column;	
  flex-direction: column;	
  -webkit-box-align: start;	
  -webkit-align-items: flex-start;	
  -ms-flex-align: start;	
}

.button-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 181px;
  height: 56px;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #ee961b;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.paragraph-6 {
  margin-bottom: 16px;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 27px;

}

.paragraph-46 {
  margin-bottom: auto;
  font-size: 16px;
  line-height: 30px;
}

.mt-3 {
  margin-top: 0 !important;
}

.btn-primary .container .label {
  font-size: 16px !important;
}

.paragraph-66 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  padding-top: 8px;
}

.paragraph-66 strong {
  padding-left: 4px;
}

.button-sub-text {
  padding-top: 20px;
  font-size: 12px;
}

.mint-box-heading {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 14px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-size: 2.2vw;
  line-height: 2.5vw;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
}

.hero-body-text {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  text-align: left;
}

.div-block-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 10px;
}

.columns-3 {
  display: inline-block;
  width: 100%;
  margin-right: auto;
  margin-bottom: 28px;
  margin-left: auto;
}

.column-7 {
  padding-right: 0px;
  padding-left: 0px;
}

.link-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.hero-gradient-splash {
  position: relative;
  z-index: 2;
  overflow: visible;
  width: 100vw !important;
  height: 100%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent !important;
  background-image: linear-gradient(298deg, hsla(0, 0%, 100%, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb) !important;
  background-clip: border-box !important;
  -webkit-text-fill-color: inherit;
}

.hero-splash {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 1400px;
  padding-right: 5vw;
  padding-bottom: 12px;
  padding-left: 5vw;
  text-align: left;
  -o-object-fit: none;
  object-fit: none;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  height: 90vh;
  max-width: 100%;
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;

  display: flex;
  flex-direction: column;
}

.text-block-5 {
  text-align: left;
}

.hero-gradient-t-and-c {
  position: relative;
  z-index: 2;
  overflow: visible;
  width: 100vw;
  height: 100%;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
  background-color: transparent;
  background-image: linear-gradient(298deg, hsla(0, 0%, 100%, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb);
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.hero-t-and-c {
  position: relative;
  z-index: 1;
  display: block;
  height: 100%;
  max-width: 1400px;
  padding-right: 100px;
  padding-bottom: 0px;
  padding-left: 100px;
  text-align: left;
  -o-object-fit: none;
  object-fit: none;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.navbar-t-and-c {
  position: static;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 4;
  display: inline-block;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 100px;
  background-color: transparent;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent); */
  font-family: Roboto, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
}

.hero-image-hawk {
  background-image: url('images/nerdsminting.png');
  height: 286px;
      background-position: top;
    background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  margin-bottom: 0px;
  flex: 1;
  margin-top: 7px;
}


.hero-content-founders-token {
  padding-top: 20px;
  top: 0px;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  display: block;
  height: auto;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  /* padding-top: 200px; */
  float: none;
  text-align: center;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.list {
  padding-left: 20px;
}

@media screen and (min-width: 1440px) {
  .hero-gradient {
    height: 100%;
  }

  .title {
    overflow: visible;
    font-size: 110px;
  }

  .image-6 {
    float: right;
  }

  .we-are-x {
    overflow: visible;
    font-size: 110px;
  }

  .togeher-the-nerd-collective {
    overflow: visible;
    padding-top: 30px;
    font-size: 110px;
  }


  .mint-box-heading {
    font-size: 2.2em;
  }

  .hero-gradient-splash {
    height: 100%;
  }

  .hero-gradient-t-and-c {
    height: 100%;
  }

  .hero-content-founders-token {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 1920px) {
  .hero-gradient {
    height: 100%;
  }

  .hero {
    position: static;
  }

  .title {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 3;
    padding-top: 120px;
    font-size: 110px;
  }

  .hero-content-t-and-c {
    position: relative;
    background-image: none;
  }

  .section---purpole {
    background-position: 70% 100%, 0px 0px;
    background-attachment: scroll, scroll;
  }

  .container-5 {
    max-width: 1400px;
    text-align: center;
  }

  .div-block-3 {
    position: static;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100%;
    height: auto;
    text-align: left;
    -o-object-fit: fill;
    object-fit: fill;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
  }

  .image-6 {
    position: static;
    right: 5px;
    float: right;
    text-align: right;
  }

  .we-are-x {
    font-size: 110px;
  }

  .togeher-the-nerd-collective {
    font-size: 110px;
  }

  .section---explorers {
    background-position: 70% 100%, 0px 0px;
    background-attachment: scroll, scroll;
  }




  .container---faq {
    padding-bottom: 0px;
  }

  .div-block-20 {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 100px;
  }

  .dropdown-list.w--open {
    margin-top: 70px;
  }

  .body-faq {
    padding-bottom: 40px;
  }

  .div-faq {
    position: relative;
    width: 100%;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 100px;
    padding-left: 100px;
  }

  .div-block-22 {
    position: relative;
    width: 100%;
    height: 1px;
    max-height: 1px;
    min-height: 1px;
    padding-right: 0px;
    padding-bottom: 0px;
    border-bottom: 0px solid rgba(0, 0, 0, 0.39);
    background-color: rgba(0, 0, 0, 0.39);
  }

  .hero-gradient-splash {
    height: 100%;
  }

  .hero-splash {
    position: static;
  }

  .hero-gradient-t-and-c {
    height: 100%;
  }

  .hero-t-and-c {
    position: static;
  }

  .hero-content-founders-token {
    position: relative;
    background-image: none;
  }

  .link-block-3 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 991px) {
  .navbar-wrapper {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .nav-menu-wrapper {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: transparent;
  }

  .nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }

  .nav-link {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 18px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .nav-button-wrapper {
    width: 100%;
    margin-left: 0px;
  }

  .button-primary {
    margin-left: 0px;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    background-color: #a6b1bf;
    color: #fff;
  }

  .navbar {
    padding-right: 40px;
    padding-left: 40px;
  }

  .hero-gradient {
    height: 100%;
    max-width: 100%;
  }

  .nav-menu-two {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    padding: 20px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
  }

  .hero {
    height: 800px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .title {
    display: inline-block;
    max-width: 100%;
    font-size: 67px;
    line-height: 69px;
    letter-spacing: 3px;
  }

  .hero-content-t-and-c {
    height: 100%;
    max-width: 100%;
  }

  .h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 51px;
  }

  .container-3 {
    height: 100%;
  }

  .paragraph {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 30px;
  }

  .section---we-are-nerds {
    height: 500px;
  }

  .social-icon-right {
    padding-right: 0px;
  }

  .list-item {
    margin-left: 0px;
    padding-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .list-item-2 {
    padding-right: 18px;
    padding-left: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .icon {
    min-height: 30px;
    min-width: 30px;
  }

  .list-item-3 {
    padding-right: 18px;
  }

  .list-item-4 {
    padding-right: 18px;
    font-size: 18px;
  }

  .list-item-bottom {
    padding-right: 18px;
  }


  .text-left {
    position: static;
    top: 0px;
    height: 100%;
  }

  .container-5 {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .we-are-x {
    display: inline-block;
    max-width: 100%;
    font-size: 67px;
    line-height: 69px;
    letter-spacing: 3px;
  }

  .togeher-the-nerd-collective {
    display: inline-block;
    max-width: 100%;
    padding-top: 30px;
    font-size: 67px;
    line-height: 69px;
    letter-spacing: 3px;
  }

  .grid {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
  }

  .paragraph-2 {
    width: 100%;
  }

  .text-block-3 {
    font-size: 14px;
  }


  .section---we-are-tnc {
    height: auto;
  }

  .sub-title {
    font-size: 24px;
  }

  .container---faq {
    padding-right: 40px;
    padding-left: 40px;
    background-color: white;
    padding-bottom: 100px;
    padding-top: 70px;
  }

  .column-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-bottom: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-24 {
    padding: 30px !important;
    margin-bottom: 30px;
  }

  .div-block-25 {
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hero-gradient-splash {
    height: 100%;
    max-width: 100%;
  }

  .hero-splash {
    height: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
  .hero-image-hawk {
    flex: none;
  }

  .hero-gradient-t-and-c {
    height: 100%;
    max-width: 100%;
  }

  .hero-t-and-c {
    height: 800px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .navbar-t-and-c {
    padding-right: 40px;
    padding-left: 40px;
  }

  .hero-content-founders-token {
    height: 100%;
    max-width: 100%;
    padding-top: 0px;
    padding: 0 40px;
  }

  .list {
    padding-top: 10px;
    padding-left: 20px;
    text-indent: 0px;
    padding-top: 0;
  }
  .w-inline-block {
    max-width: 100%;
    display: inherit !important;
}
}

@media screen and (max-width: 767px) {
  .navbar-wrapper {
    position: relative;
    width: 100vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .navbar-logo {
    padding-left: 0px;
  }

  .nav-menu-wrapper {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 800%;
    margin-top: 120px;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-top: 1px solid #fff;
    background-color: rgba(59, 59, 59, 0.73);
    -o-object-fit: fill;
    object-fit: fill;
  }

  .nav-menu {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding-right: 0px;
    padding-bottom: 30px;
    padding-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: transparent;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .nav-link {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-weight: 400;
    text-align: left;
  }

  .nav-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .menu-button {
    min-height: 44px;
    min-width: 44px;
    background-image: url('images/hamburger-menu.png');
    background-position: 50% 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .menu-button.w--open {
    background-color: transparent;
    background-image: url('images/hamburger-close.png');
    background-position: 50% 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .navbar {
    top: auto !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .navbar-logo-left-container {
    max-width: 100%;
  }

  .navbar-logo-left-container.shadow-three {
    position: relative;
    width: 100vw;
    text-align: left;
  }

  .hero-gradient {
    height: 100%;
  }

  .nav-menu-two {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px;
  }

  .nav-divider {
    width: 200px;
    height: 1px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-link-accent {
    display: inline-block;
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hero {
    height: 800px;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .title {
    overflow: visible;
    width: 100%;
    padding-top: 150px;
    font-size: 8.1vw;
    line-height: 11vw;
  }

  .hero-content-t-and-c {
    position: relative;
    margin-top: 0px;
    padding-top: 130px;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .container-3 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .list-item {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .list-item-2 {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-top: 0px solid #fff;
  }

  .container-4 {
    height: auto;
    padding: 40px;
    background-color: #383838;
  }

  .column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .column-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .columns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .image-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    text-align: right;
  }

  .list-item-3 {
    width: 100%;
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-top: 1px solid #fff;
  }

  .list-item-4 {
    width: 100%;
    padding-top: 20px;
    padding-right: 0px;
    padding-bottom: 20px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .section---purpole {
    height: 800px;
    background-position: 50% 100%, 0px 0px;
  }

  .text-left {
    width: 100%;
    height: auto;
    text-align: center;
  }

  .div-block-2 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .social-icon {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .container-5 {
    padding-top: 30px;
    padding-right: 5vw;
    padding-left: 5vw;
    -o-object-fit: none;
    object-fit: none;
  }

  .div-block-3 {
    max-width: 100%;
  }

  .image-5 {
    height: 30px;
  }

  .we-are-x {
    overflow: visible;
    width: 100%;
    padding-top: 0px;
    font-size: 8.1vw;
    line-height: 11vw;
  }

  .togeher-the-nerd-collective {
    overflow: visible;
    width: 100%;
    padding-top: 0px;
    font-size: 8.1vw;
    line-height: 11vw;
  }

  .team {
    padding-right: 20px;
    padding-left: 20px;
  }

  .grid {
    margin-top: 40px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .footer {
    height: 100%;
    padding-bottom: 20px;
  }

  .image-10 {
    height: 50px;
  }

  .div-block-10 {
    padding-top: 90px !important;
    padding-right: 20px;
    padding-left: 20px;
  }

  .div-block-11 {
    height: 149px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .text-block-3 {
    font-size: 14px;
  }

  .container-7 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .div-block-13 {
    left: auto;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .heading-4 {
    text-align: center;
  }

  .paragraph-3 {
    text-align: center;
  }

  .div-block-14 {
    text-align: center;
  }

  .section---explorers {
    height: 800px;
    background-position: 50% 100%, 0px 0px;
  }

  .hero-nerds {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    background-size: 600px;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .hamburger.open {
    width: 30px;
    height: 30px;
  }

  .hamburger.closed {
    width: 30px;
    height: 30px;
    min-width: auto;
  }

  .navbar-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .sub-title {
    font-size: 20px;
  }

  .mint-box-heading {
    font-size: 30px;
  }

  .hero-gradient-splash {
    height: 100%;
  }

  .hero-splash {
    height: 100%;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .hero-gradient-t-and-c {
    height: 100%;
  }

  .hero-t-and-c {
    height: 800px;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .navbar-t-and-c {
    top: auto;
    padding-right: 0px;
    padding-left: 0px;
  }

  .hero-content-founders-token {
    position: relative;
    margin-top: 0px;
    padding-top: 0px;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
    padding: 0 40px;
  }
}

@media screen and (max-width: 850px) {
  .navigation---background {
    width: 100%;
    padding-right: 1px !important;
    padding-left: 4px !important;
  }
}

@media screen and (max-width: 479px) {
  .navbar-wrapper {
    top: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .nav-menu-wrapper {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100vw;
    height: 100vh;
    margin-top: 100px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .nav-menu {
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top: 0px solid #fff;
    background-color: rgba(60, 60, 60, 0.73);
  }

  .nav-link {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
  }

  .nav-button-wrapper {
    width: auto;
  }

  .button-primary {
    min-width: 280px;
    margin-left: 0px;
    padding-right: 60px;
    padding-left: 60px;
    background-color: #2a707a;
  }

  .menu-button {
    width: 30px;
    height: 30px;
    padding: 0px;
    background-image: url('images/hamburger-menu.png');
    background-position: 50% 50%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
  }

  .menu-button.w--open {
    background-color: transparent;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .navbar {
    position: initial;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 0px;
  }

  .navbar-logo-left-container.shadow-three {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .hero-gradient {
    height: 530px;
    max-width: none;
  }

  .nav-menu-two {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.82);
    list-style-type: disc;
    mix-blend-mode: normal;
  }

  .hero {
    height: auto;
    max-width: 100%;
    padding-top: 10px;
    padding-right: 2vw;
    padding-left: 2vw;
  }

  .title {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
    font-size: 9vw;
    line-height: 11vw;
    letter-spacing: 2px;
  }

  .hero-content-t-and-c {
    height: auto;
    max-width: 100%;
    min-height: 400px;
    margin-top: 120px;
    padding-top: 0px;
    background-clip: content-box;
    -webkit-text-fill-color: inherit;
  }

  .body {
    height: 100vh;
  }

  .h2 {
    font-size: 36px;
    line-height: 44px;
  }

  .h2.black {
    margin-bottom: 20px;
    line-height: 45px;
  }

  .h2.white {
    line-height: 45px;
  }

  .h2.white.tnc {
    font-size: 8vw;
    line-height: 140%;
  }

  .container-3 {
    max-width: 100%;
    min-height: 620px;
    padding: 100px 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .container-3.tnc {
    min-height: auto;
  }

  .container-3.builders {
    min-height: 567px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .container-3.explorers {
    min-height: auto;
  }

  .container-3.nerds {
    min-height: 600px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .container-3.description {
    min-height: auto;
  }

  .paragraph.white {
    margin-bottom: 12px;
    padding-top: 0px;
  }

  .paragraph.black {
    padding-top: 0px;
  }

  .text-center.top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .list-item {
    display: -ms-grid;
    display: grid;
    width: 50%;
    padding: 20px 10px 20px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-items: start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    align-self: flex-start;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ". Area";
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;
  }

  .list-item-2 {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-top: 0px solid transparent;
    border-bottom: 1px solid #fff;
    text-align: left;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .list-item-3 {
    width: 100%;
    height: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid #fff;
    border-top-width: 0px;
    text-align: left;
  }

  .list-item-4 {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    border-bottom: 1px solid #fff;
    border-top-width: 0px;
    text-align: left;
  }

  .list-item-bottom {
    display: -ms-grid;
    display: grid;
    width: 50%;
    padding: 30px 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    align-self: flex-start;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ". Area";
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;
  }

  .section---purpole {
    height: auto;
    max-width: 100%;
    background-size: auto 110vw, auto;
    font-family: Roboto, sans-serif;
    color: #000;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .text-left.builders {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
  }

  .div-block-2 {
    max-height: none;
    min-height: auto;
    min-width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    color: #fff;
  }

  .image-6 {
    width: 30px;
  }

  .we-are-x {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
    font-size: 8vw;
    line-height: 11vw;
    letter-spacing: 2px;
  }

  .togeher-the-nerd-collective {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
    font-size: 8vw;
    line-height: 11vw;
    letter-spacing: 2px;
  }

  .team {
    padding-right: 10px;
    padding-left: 10px;
  }

  .grid {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .team-card {
    height: 100%;
    min-width: 145px;
    margin: auto;
  }

  .heading-3 {
    min-width: 145px;
    font-size: 20px;
  }

  .div-block-6 {
    height: 100%;
  }

  .div-block-7 {
    position: static;
  }

  .image-9 {
    min-height: auto;
  }

  .div-block-9 {
    border-radius: 8px;
  }

  .footer {
    height: 100%;
    background-color: #353535;
  }

  .div-block-11 {
    padding-right: 10px;
    padding-left: 10px;
  }

  .text-block-3 {
    font-size: 14px;
    letter-spacing: 1px;
  }


  .container-7 {
    padding: 100px 10px 60px;
  }

  .section---explorers {
    height: 750px;
    max-width: 100%;
    background-size: 86vw, auto;
    font-family: Roboto, sans-serif;
    color: #000;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .section---product {
    height: auto;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    background-image: url('images/chart-background-image-mobile.png'), linear-gradient(122deg, #39343c, #0b0613);
    background-position: 0% 100%, 0px 0px;
    background-size: 100%, auto;
    font-family: Roboto, sans-serif;
    color: #000;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }
  .hero-nerds {
    max-height: none;
    min-height: auto;
    background-size: auto 100%;
    background-attachment: scroll;
  }


  .section---we-are-tnc {
    height: auto;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    font-family: Roboto, sans-serif;
    color: #000;
    background-clip: border-box;
    -webkit-text-fill-color: inherit;
  }

  .heading-6 {
    font-size: 40px;
    line-height: 50px;
  }

  .heading-6.tnc {
    margin-top: 0px;
    font-size: 9.5vw;
  }

  .div-block-16 {
    max-width: 100%;
    padding: 0px;
    border-width: 0px;
  }

  .hamburger {
    position: relative;
    z-index: 2;
  }

  .hamburger.open {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }

  .hamburger.closed {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 1;
  }

  .div-block-17 {
    position: relative;
    background-color: hsla(0, 0%, 100%, 0);
  }

  .div-block-18 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .sub-title {
    font-size: 20px;
    line-height: 28px;
  }

  .container---faq {
    margin-top: 0px;
    padding-top: 50px;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .header-faq {
    font-size: 20px;
  }

  .body-faq {
    font-size: 16px;
    line-height: 26px;
  }

  .div-block-24 {
    position: relative;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .paragraph-6 {
    font-size: 16px;
  }

  .button-sub-text {
    font-size: 12px;
  }

  .mint-box-heading {
    font-size: 5.5vw;
  }

  .hero-body-text {
    font-size: 16px;
    line-height: 28px;
  }

  .columns-3 {
    height: auto;
    margin-bottom: 0px;
  }

  .hero-gradient-splash {
    display: inline-block;
    height: auto;
    max-width: none;
    padding-bottom: 0px;
  }

  .hero-splash {
    height: auto;
    max-width: 100%;
    padding-top: 10px;
    padding-right: 5vw;
    padding-left: 5vw;
  }

  .hero-gradient-t-and-c {
    height: 530px;
    max-width: none;
  }

  .hero-t-and-c {
    height: auto;
    max-width: 100%;
    padding-top: 10px;
    padding-right: 2vw;
    padding-left: 2vw;
  }

  .navbar-t-and-c {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
    padding: 20px 0px;
  }

  .hero-content-founders-token {
    height: auto;
    max-width: 100%;
    min-height: 0px;
    margin-top: 0px;
    padding-top: 80px;
    background-clip: content-box;
    -webkit-text-fill-color: inherit;
  }
}

#w-node-_212b3530-1965-21b2-6ca9-2e1a79e180ab-79e180ab {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 479px) {
  #w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-03e2212b {
    grid-area: Area;
  }

  #w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-6949c9c0 {
    grid-area: Area;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-03e2212b {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .list-item-bottom>#w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-03e2212b {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  #w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-6949c9c0 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  .list-item-bottom>#w-node-_716f9f1b-0023-c583-778a-f364bab5ad41-6949c9c0 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}


.navbar {
  position: initial;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 4;
  display: inline-block;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 40px 100px;
  background-color: transparent;
  /* background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent));
  background-image: linear-gradient(180deg, transparent, transparent); */
  font-family: Roboto, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;

  /* background-color: transparent;
    background-image: linear-gradient(298deg, hsla(0, 0%, 100%, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb); */
}
.navbar.hidden-navbar {
  box-shadow: none;
  transform: translate3d(0, -100%, 0);
}
.list-item-bottom {
  padding-right: 20px;
  padding-left: 0px;
}
.nav-menu-wrapper {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}

.navbar-logo-left-container {
  z-index: 5;
  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: transparent;
}
.social-icon {
  display: inline-block;
  width: auto;
  max-width: none;
  min-width: 40px;
  padding-right: 0px;
  float: none;
}
.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: none;
  margin-bottom: 0px;
}

.navbar-logo {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.list-item {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 60px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-11 {
  width: 140px;
  height: 40px;
  max-height: 40px;
  max-width: 140%;
  min-height: 40px;
  min-width: 140px;
}
.link-block {
  float: right;
}
.navbar-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: none;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  -o-object-fit: fill;
  object-fit: fill;
}

@media screen and (max-width: 991px) {
  .list-item-bottom {
    padding-right: 18px;
  }
  .nav-menu-wrapper {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    background-color: transparent !important;
  }
  .navbar-wrapper {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .list-item {
    margin-left: 0px;
    padding-right: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

@media screen and (max-width: 767px) {
  .social-icon {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .list-item {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .nav-menu-wrapper {
    display: none;
  }
  .navbar-logo {
    padding-left: 0px;
  }
  .navbar-wrapper {
    position: relative;
    width: 100vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }

  .navbar-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .navbar-logo-left-container {
    max-width: 100%;
  }

  .navbar-logo-left-container.shadow-three {
    position: relative;
    width: 100vw;
    text-align: left;
  }
}

@media screen and (max-width: 479px) {
  .list-item-bottom {
    display: -ms-grid;
    display: grid;
    width: 50%;
    padding: 30px 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    align-self: flex-start;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ". Area";
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;
  }
  .list-item {
    display: -ms-grid;
    display: grid;
    width: 50%;
    padding: 20px 10px 20px 0px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-items: start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    align-self: flex-start;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-areas: ". Area";
    -ms-grid-columns: 1fr 16px 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: left;
  }
  .nav-menu-wrapper {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100vw;
    height: 100vh;
    margin-top: 100px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .navbar-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-wrapper {
    top: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .navbar-logo-left-container.shadow-three {
    position: initial;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

#Mintdetails {
  background-color: #d9d9d9;
}
.div-block-28 {
  display: flex;
  align-items: center;
  padding: 10px 0 0 0px;
}
.text---disclaimer-text {
  display: inline-block;
  overflow: auto;
  max-width: 600px;
  margin-bottom: 0px;
  padding-top: 10px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.div-block-29 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 40px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.header---disclaimer {
  display: inline-block;
  overflow: auto;
  max-width: 600px;
  margin-bottom: 0px;
  padding-top: 0px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
  -o-object-fit: fill;
  object-fit: fill;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
@media screen and (max-width: 767px) {
  .div-block-29 {
      margin-top: 40px;
  }
}
.csschange {
  color: #fff;
  display: flex;
  flex-direction: column;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.div-block-33 {
  padding-bottom: 40px;
  text-align: left;
}
.div-block-32 {
  border-top: 1px solid hsla(0, 0%, 100%, 0.7);
}
.columns-6 {
  margin-bottom: 100px;
  color: #fff;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.column-14 {
  padding-right: 0px;
  padding-left: 0px;
}
.nav-item---footer.right-footer {
  text-align: right;
}
.nav-item---footer:hover {
  color: rgba(255, 255, 255, 0.75);
}
.nav-item---footer {
  margin-right: auto;
  margin-left: auto;
  padding: 40px 0px 5px;
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  font-weight: 300;
  text-align: left;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.div-block-33 img {
  height: 70px;
  padding-top: 14px;
}
.div-block-35 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.footer-link {
  text-decoration: none;
}
.social-icon---footer {
  padding-left: 20px;
  text-align: right;
}
.div-block-34 {
  padding-top: 10px;
  text-align: right;
}
.footer {
  height: 100%;
  padding: 100px 100px 50px;
  background-color: #353535;
}
.link-3 {
  color: hsla(0, 0%, 100%, 0.65);
}
.footer-text {
  color: hsla(0, 0%, 100%, 0.65);
  font-weight: 300;
  text-align: left;
  letter-spacing: 1px;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.w-nav:before, .w-nav:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.navigation-container {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
}
.navigation-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.navigation-logo {
  padding-right: 40px;
}
.w-nav-brand {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
.image-13 {
  width: auto;
  height: 60px;
}
.nav-menu-wrapper-2 {
  position: static;
  background-color: transparent;
}
.navigation-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.w-nav-button {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.w-nav-overlay {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
.w-nav:after {
  clear: both;
}
.nav-item-container {
  padding-right: 40px;
  height: 100%;
}

.nav-item {
  margin-right: auto;
  margin-left: auto;
  padding: 5px 0px;
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
.navigation---background {
  width: 100%;
  padding-right: 100px;
  padding-left: 100px;
  /* background-image: linear-gradient(146deg, rgba(0, 181, 192, 0.34), hsla(0, 0%, 85.1%, 0)), linear-gradient(180deg, #8b9dbb, #8b9dbb); */
}
.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: none;
  margin-bottom: 0px;
  padding: 0px;
}
.navbar-logo-left-container {
  z-index: 5;
  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: transparent;
}
.w-nav {
  position: relative;
  /* background: #dddddd; */
  z-index: 1000;
}
.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
}
.list-item-4 {
  padding-right: 40px;
}
.dropdown-2 {
  padding-right: 0px;
}
.w-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  z-index: 900;
}
.nav-item-container.icon {
  padding-right: 20px;
  width: inherit;
}
.navigation-social-media-icon.right {
  padding-left: 20px;
}

.image-13 {
  width: auto;
  height: 44px;
}


@media screen and (max-width: 576px) {
  .navbar-logo-left-container.shadow-three {
    width: 94%;
    max-width: 1200px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-left: 0px;
    left: 13px;
  }
  .nav-item---footer.right-footer {
    text-align: left;
  }
  .div-block-34 {
    text-align: left;
  }
  .social-icon---footer {
    text-align: left;
    padding-left: 0px;
    padding-top: 4px;
  }
  .hero-content-founders-token {
    display: block;

    padding-top: 0px;
  }
  .hero-image-hawk {
    flex: none;
  }
}
@media screen and (max-width: 1200px) {
  .hero-splash {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}