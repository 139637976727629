html {
	 width: 100%;
	 height: 100%;
	 background-color: #e5e5e5;
	 background-position: center;
	 background-repeat: no-repeat;
	 background-size: auto;
}
 body {
	 color: #191919;
	 width: 100%;
	 height: 100%;
	 font-family: 'Roboto', sans-serif !important;
}
 #app {
	 width: 100%;
}
 .btn-primary {
	 transition: all 0.2s ease, visibility 0s;
	 border-radius: 50px;
	 background: #6cb9ff;
	 border: 1px solid #191919;
	 display: block;
	 min-width: 10px;
	 min-height: 10px;
	 box-sizing: border-box;
	 padding: 0px;
	 height: 46px;
	 cursor: pointer;
	 margin: 0 auto;
}
 .btn-primary.active {
	 background: #ff8736;
}
 .btn-primary .container {
	 display: flex;
	 flex-basis: auto;
	 justify-content: center;
	 flex-direction: row;
	 flex-grow: 1;
	 align-items: center;
	 overflow: hidden;
	 height: 100%;
	 width: 100%;
	 transition: all 0.2s ease, visibility 0s;
}
 .btn-primary .container .label {
	 transition: inherit;
	 letter-spacing: 0.1em;
	 color: #e4e84f;
	 font-family: "suez one", serif;
	 text-shadow: 1px 0 #191919, -1px 0 #191919, 0 1px #191919, 0 -1px #191919;
	 font-size: 21px;
	 padding: 10px 20px;
}
 .fully-center {
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 text-align: center;
}
.progress {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 9999px;
    display: block;
    height: 1rem;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
.progress:indeterminate {
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: moveIndeterminate;
    animation-timing-function: linear;
    background-color: #ededed;
    background-image: linear-gradient(90deg,#4a4a4a 30%,#ededed 0);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 150% 150%;
}
.progress.is-small {
    height: 0.75rem;
}
 .mint {
	 max-width: 450px;
	 max-height: 350px;
	 overflow: hidden;
	 font-family: sans-serif;
	 font-weight: 600;
	 width: 450px;
	 height: 350px;
	 border: 9px solid #ff8736;
	 border-radius: 4px;
	 padding: 25px;
	 background-color: transparent;
	 background-image: linear-gradient(298deg, rgba(255, 255, 255, 0), rgba(0, 181, 192, 0.34)), linear-gradient(180deg, #8b9dbb, #8b9dbb);
	 background-clip: border-box;
	 -webkit-text-fill-color: inherit;
}
 .progress.is-primary:indeterminate {
	 background-image: linear-gradient(to right, #ff8736 30%, #ededed 30%);
}
 .notification.is-primary {
	 background-color: #ff8736;
	 color: #fff;
}
 .notification.is-link {
	 background-color: transparent;
	 color: #000;
}
 .mint-box-heading {
	 position: relative;
	 display: block;
	 width: 100%;
	 margin-top: 0px;
	 margin-bottom: 0px;
	 padding-bottom: 14px;
	 -webkit-box-align: start;
	 -webkit-align-items: flex-start;
	 -ms-flex-align: start;
	 align-items: flex-start;
	 -webkit-align-self: flex-start;
	 -ms-flex-item-align: start;
	 align-self: flex-start;
	 -webkit-box-flex: 0;
	 -webkit-flex: 0 auto;
	 -ms-flex: 0 auto;
	 flex: 0 auto;
	 font-size: 2.2vw;
	 line-height: 2.5vw;
	 text-align: left;
	 -o-object-fit: fill;
	 object-fit: fill;
}
 .btn-primary .container .label {
	 color: #fff;
	 text-shadow: none;
	 letter-spacing: normal;
	 padding: 0px;
	 font-family: Roboto, sans-serif;
	 font-size: 18px;
	 font-weight: 500;
	 text-align: center;
}
 .btn-primary {
	 border: 0px;
	 display: flex;
	 width: 181px;
	 height: 56px;
	 margin-top: auto;
	 margin-bottom: auto;
	 padding-top: 0px;
	 padding-bottom: 0px;
	 flex-direction: column;
	 justify-content: center;
	 border-radius: 5px;
	 background-color: #ee961b;
	 font-family: Roboto, sans-serif;
	 font-size: 18px;
	 font-weight: 500;
	 text-align: center;
	 padding: 9px 15px;
	 color: white;
	 border: 0;
	 line-height: inherit;
	 text-decoration: none;
	 cursor: pointer;
}
 
@keyframes moveIndeterminate {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
  }